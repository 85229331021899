const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/downloads"],
    component: "Downloads",
  },
  // {
  //   path: ["/reports/cpf/:cpf/birth/:birth"],
  //   component: "Reports",
  // },
  {
    path: ["/reports/customerId=:id"],
    component: "Reports",
  },
  {
    path: ["/reports/professionals/customerId=:id"],
    component: "ReportsProfessional",
  },
  {
    path: ["/reports/cpf/:cpf/birth/:birth"],
    component: "ReportsCustomer",
  },
  {
    path: ["/reports/oauth/customerId=:id"],
    component: "Authentication",
  },
];

export default routes;
