import styled from "styled-components";

export const MiddleBoxSection = styled("section")`
  position: relative;
  padding: 10rem 0 0.5rem;
  text-align: center;
  justify-content: center;
  display: flex;

  @media screen and (max-width: 1024px) {
    padding: 5.5rem 0 1rem;
  }
`;

export const Content = styled("p")`
  padding: 0rem;
`;

export const ContentWrapper = styled("div")`
  max-width: 570px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Center = styled("div")`
  justify-content: center;
  text-align: center;
  display: flex;
`;

export const MiddleBoxContent = styled("p")`
  padding: 0.5rem 0 0.5rem;
  font-size: 16px;
`;

export const MiddleBoxButtonContent = styled("div")`
  position: relative;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 414px) {
    padding-top: 0.75rem;
  }
`;

export const MiddleBoxButton = styled("button")<any>`
  background: #1b8ef7;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: 1px solid #edf3f5;
  border-radius: 4px;
  padding: 0.7rem 0;
  cursor: pointer;
  margin-top: 0.625rem;
  max-width: 8rem;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid #1b8ef7;
    background-color: #1b8ef7;
  }
`;

export const AlertError = styled("section")`
  position: relative;
  padding: 0.5rem;
  text-align: center;
  justify-content: center;
  display: flex;

  @media screen and (max-width: 1024px) {
    padding: 5.5rem 0 1rem;
  }
`;
