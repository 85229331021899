
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { MiddleBoxSection, MiddleBoxContent, ContentWrapper} from "./styles";

interface MiddleBoxProps {
  title: string;
  content: string;
  t: any;
}

const MiddleBox = ({ title, content, t }: MiddleBoxProps) => {
  return (
    <MiddleBoxSection>
        <Row justify="center" align="middle">
          <ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>
              <p style={{fontSize: 30, color: '#1b8ef7', fontWeight: 'bolder', marginBottom: 0.5}}>{t(title)}</p>
              <MiddleBoxContent>{t(content)}</MiddleBoxContent>
            </Col>
            
          </ContentWrapper>
        </Row>
       
    </MiddleBoxSection>
  );
};

export default withTranslation()(MiddleBox);
