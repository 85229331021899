import React, {lazy, useEffect, useState, useMemo} from "react";
import IntroContent from "../../content/IntroContent.json";
import MiddleBlockContent from "../../content/MiddleBlockContent.json";
import AboutContent from "../../content/AboutContent.json";
import ProductContent from "../../content/ProductContent.json";
import ContactContent from "../../content/ContactContent.json";
import MiddleBlox from "../../components/MiddleBlox";

const Contact = lazy(() => import("../../components/ContactForm"));
const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../../components/ContentBlock"));


const Redirect = () => {

  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    
    setIsLoading(true);
    window.location.href = 'https://achemed-store.inkless.digital/';

}, 2000);

  return (
    <Container>
      <ScrollToTop />
      <div style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <MiddleBlox
          title={MiddleBlockContent.title}
          content={MiddleBlockContent.text}
        />
      </div>

      <div style={{ display: isLoading ? 'flex' : 'none' }} className='modal'>
        <div className='modal-content'>
          <div className='loader'></div>
          <div className='modal-text'>Carregando Informações de Download...</div>
        </div>
      </div>
      
    </Container>
  );
};

export default Redirect;
